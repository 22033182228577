h1,
h2,
h3,
h4,
h5,
h6,
* {
	margin: 0;
	padding: 0;
}
body {
	background-color: #f6f6f6;
}
.page {
	padding: 10px;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	flex-direction: column;
}
.CenterImage {
	padding: 20px;
	object-fit: contain;
	width: 100%;
	max-height: 50vh;
	@media only screen and (max-height: 700px) {
		max-height: 32vh;
	}
}
.Input {
	width: 100%;
	background-color: #f6f1fb;
	color: #818282;
	height: 53px;
	margin: 10px 0;
	font-size: 16px;
	border-radius: 5px;
	font-weight: 500;
	border: 1px solid #f6f1fb;
	padding: 0px 15px;
}
p {
	font-weight: 600;
}

.Paragraph {
	font-size: 21px;
	@media only screen and (max-width: 320px) {
		font-size: 16px;
	}
	text-align: center;
}

.Splash {
	width: 100%;
	height: 100vh;
	background-attachment: fixed;
	background-image: url('../Images/Splash.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
	display: flex;
	align-items: flex-end;
	align-content: center;
	justify-content: center;
	color: white;
	font-size: 30px;
	.heading {
		color: white;
		font-size: 36px;
		margin-bottom: 50px;
	}
}
.Header {
	width: 100%;
	background: #fff;
	color: #000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	border-radius: 11px;
	padding: 15px;

	.icon-button {
		border: 1px solid #ebeaec;
		font-size: 20px;
		border-radius: 50%;
		padding: 10px;
		color: #3f414e;
	}

	.HeaderSectionOne {
		display: flex;
		align-items: flex-start;
		align-content: center;
		justify-content: flex-start;
		width: 100%;
		margin-bottom: 10px;
	}

	.Year {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		justify-content: center;
		margin: 0% 2%;
		font-size: 21px;
		font-weight: 600;
		p {
			margin: 0px 5px;
		}
		svg {
			font-size: 15px;
		}
	}
	.HeaderSectionTwo {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		align-content: center;
		width: 100%;
		padding: 0px;
	}
	.HeaderHead {
		font-size: 21px;
		font-weight: 600;
	}

	.SectionHead {
		font-size: 14px;
		font-weight: 600;
		margin: 0px;
		padding: 5px;
	}
	.SectionPara {
		font-size: 13px;
		font-weight: 200;
		margin: 0px;
		padding: 0px 5px;
	}

	.Icon {
		margin: 15px 0px;
		padding: 0px 15px;
		cursor: pointer;
		&:not(.disabled) {
			color: #2680eb;
		}
		svg {
			width: 1.5em;
			height: 1.5em;
		}
	}
}

.Content {
	margin-top: 20px;
	padding: 10px;
	width: 100%;
	background: #fff;
	border-radius: 20px;
	&.small-corner {
		margin-top: 10px;
		border-radius: 11px;
	}
}
.app-btn {
	width: 100%;
	background-color: #fff;
	color: #000;
	font-size: 16px;
	font-weight: 500;
	text-align: center;
	margin: 5px 0;
	height: 50px;
	&:not(.round) {
		border-radius: 5px;
		border: 0px;
		// &.Blue,
		// &.Dotted {
		//   height: 53px;
		// }
	}
	&.round {
		// height: 40px;
		border-radius: 50px;
	}
	// Color of the Button inside the Dashboard to get linked to Comparison-Overview
	// Origin Version the Button was Blue, so it called Blue although it is Yellow
	&.Blue {
		background-color: #2B528D !important;
		color: #f6f1fb !important;
	}
	&.Dotted {
		border: 1px dashed #2B528D;
	}
}
.ButtonDiv {
	max-width: 400px;
	width: 100%;
	margin: 10px auto;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	// align-content: center;
	justify-content: center;

	.ParagraphTwo {
		font-size: 16px;
		@media only screen and (max-width: 320px) {
			font-size: 14px;
		}
		margin: 5px;
		text-align: center;
	}
}
.TabPanel {
	.Tabs {
		width: 100%;
		.Tab {
			margin: 0 5px;
			border-radius: 50px;
			padding: 5px 15px;
			text-align: center;
			background-color: #f4f4f4;
			color: #787878;
		}
		// .ant-btn:hover,
		// .ant-btn:focus {
		//   outline: none !important;
		// }
		.active {
			background-color: #787878;
			color: #f4f4f4;
			border: 0px;
		}
	}
}
.Para {
	font-size: 16px;
	margin: 10px 0;
	margin-top: 0;
	text-align: center;
	font-weight: 500;
}
.TableMeter {
	margin: 20px 0;
	width: 100%;
	.TableHeading {
		background-color: #fff;
		padding: 5px 10px;
	}
}

.ant-modal-content {
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}

.carousel-dots {
	bottom: -12px !important;
	li > button {
		background: #999 !important;
	}
}
.chartOuter {
	position: relative;
  .chartData {
    /*padding-top: 20px;*/
  	padding-bottom: 20px;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    margin: 0 auto;
    position: absolute;
    top: 82px;
    left: calc(50% - 79px);
    // background: red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .units {
      font-size: 26px;
    }
    .diffrence {
      display: flex;
      align-items: center;
      span {
        font-size: 30px;
        color: red;
      }
      .devider {
        border-right: 1px solid black;
        margin: 8px;
        height: 25px;
        display: inline-block;
      }
    }
    .range {
	  position: absolute;
      font-size: 21.6px;
      margin-top: 200px;
      color: #575757;
    }
  }
  .chart-label {
    font-size: 20px;
    margin: 20px 0;
    // margin-top: 0;
  	margin-bottom: 10px;
    text-align: center;
    font-weight: 500;
  }
}
