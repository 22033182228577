@import "~antd/dist/antd.css";

.Desktop {
	font-size: 40px;
	font-weight: 100;
	width: 100%;
	height: 100vh;
	text-align: center;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	overflow: hidden;
}
